import * as React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ContentWrapper } from '../components/common';
import Seo from '../components/seo';
import { HOME } from '../routes';
import { Link } from 'gatsby';
import theme from '../theme';
import logoSrc from '../images/logo-rezidence-tresnovka.png';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.white};

  img {
    width: 100%;
    height: auto;
    max-width: 220px;
    margin-bottom: 2rem;
  }
`;

const StyledLink = styled(Link)`
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-family: 'Oswald', Georgia, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
  border: 0;
  width: 100%;
  padding: 0.575rem 2.25rem;
  transition: all .3s ease;
  border: 1px solid ${({theme}) => theme.colors.primary};
  border-radius: 50px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: ${({theme}) => theme.colors.white};
    background-color:${({theme}) => theme.colors.primary_hover};
    border: 1px solid ${({theme}) => theme.colors.primary_hover};
  }
`;

const Title1 = styled.h1`
  font-size: 3.125rem;
  line-height: 3.75rem;
  font-family: 'Oswald', Georgia, sans-serif;
  color: ${({theme}) => theme.colors.primary};
  font-weight: 700;
  text-align: left;
  margin-bottom: 4.375rem;
  text-transform: uppercase;

  @media (min-width: 992px) {
    font-size: 4rem;
    line-height: 4.5rem;
  }
`;

const Container = styled.div`
  padding: 7rem 0;
  text-align: center;

  ${Title1} {
    font-size: 3.125rem;
    line-height: 3.75rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
    text-align: center;

    @media (min-width: 992px) {
      font-size: 5rem;
      line-height: 6.25rem;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 3.75rem;
    margin-bottom: 2.5rem;
  }
`;

function NotFoundPage() {

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Seo title="Stránka nebyla nalezena" />
        <Container>
          <ContentWrapper>
            <img src={logoSrc} alt="Logo - Rezidence Třešňovka" />
            <Title1>404</Title1>
            <p>Stránka nebyla nalezena</p>
            <StyledLink to={HOME}>Přejít na hlavní stránku</StyledLink>
          </ContentWrapper>
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
}

export default NotFoundPage;
